import './css/App.css';
import MainHeader from './MainHeader';
import Projects from './Projects';
import AboutMe from './AboutMe';
import Footer from './Footer';

function App() {
  return (
    
    <div className="App">

{/*       <Snow /> */}
      <MainHeader />
      <Projects />
      <AboutMe />  
      <Footer />
    </div>
  );
}

export default App;
