import { useEffect, useState } from 'react';
import SocialIcons from './SocialIcons';
function MainHeader() {

  // Change color of navbar if scrolling
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(20);

  useEffect(() => {
    function onScroll() {
      let currentPosition = window.pageYOffset && document.documentElement.clientHeight > 800; // or use document.documentElement.scrollTop;
      
      if (currentPosition > scrollTop) {
        // downscroll code
        if (scrollTop > 15) {
          document.getElementById('navbar').classList.remove('navbar');
          document.getElementById('navbar').classList.add('black-navbar');
        }
        setScrolling(false);
      } else if (scrollTop <= 15){
        // upscroll code
        document.getElementById('navbar').classList.remove('black-navbar');
        document.getElementById('navbar').classList.add('navbar');
      } else if (currentPosition <= 0){
        document.getElementById('navbar').classList.remove('black-navbar');
        document.getElementById('navbar').classList.add('navbar');
      }
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    }

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  return (
    <div className='navbar' id='navbar'>
      <div className='name'>Raquel S. Ventero</div>
      <SocialIcons />
    </div>
  );
}
export default MainHeader;
