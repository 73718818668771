function AboutMe() {
  return (
    <div className='wrapper-about-me'>
      <h3>Hi! I am Raquel Sánchez Ventero</h3>
      <p>
        <strong>React Front-end Developer</strong>, with <strong>20 years of experience</strong>, updated to the
        last technologies (<strong>REACT, Javascript ES6</strong>, Bootstrap, MongoDb, Node). I
        co-founded the <strong>largest Spanish-speaking online community & website</strong> in
        1996 and created the <strong>award-winning app Tintarantin</strong> in 2017. Constant learner, extremely motivated for new challenges. Creative
        and confident in generate new ideas and <strong>solve problems</strong>. I love to
        support small/medium projects to grow them to <strong>success</strong>.
      </p>
    </div>
  );
}

export default AboutMe;
