
import { BsGithub, BsLinkedin } from 'react-icons/bs';
function SocialIcons() {
  // Change size of SOCIAL ICONS depending of window size
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  let fontSizeValue = '';
  width > 450 ? (fontSizeValue = '3rem') : (fontSizeValue = '2rem');

  return (
    <div className='wrapper-social-icons'>
      {/*         <img className='circle RSV' src={RSV} alt='' /> */}
      <div>
        <a href='https://github.com/RaquelWriter/'>
          <BsGithub className='socialIcon' fontSize={fontSizeValue} target='blank' rel="noopener noreferrer"/>
        </a>
      </div>
      <div>&nbsp;</div>
      <div>
        <a
          
          href='https://www.linkedin.com/in/raquelsanchezventero/'
        >
          <BsLinkedin className='socialIcon' fontSize={fontSizeValue} target='blank' rel="noopener noreferrer"/>
        </a>
      </div>
    </div>
  );
}

export default SocialIcons;
