import SocialIcons from './SocialIcons';
function Footer () {
    return (
        <div className="wrapper-footer">
            <SocialIcons />
            <p>Do you like this React webpage? <a href="https://www.linkedin.com/in/raquelsanchezventero/" target="blank" rel="noopener noreferrer">Contact me!</a></p>
            <p>Available for work</p>
            <p>Raquel Sánchez Ventero © 2022</p>
        </div>
    );
};
export default Footer;