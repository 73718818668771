import { useState } from 'react';
import { BsGithub, BsLink45Deg } from 'react-icons/bs';
import './css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import GoogleMaps from './images/googlemaps_anim.gif';
import Tintarantin from './images/tintarantin_anim.gif';
import ShoppingCart from './images/shoppingcart_anim.gif';
import BalconDeCordoba from './images/balcondecordoba_anim.gif';
import NeyaSound from './images/neyasound_anim.gif';

function Projects() {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const textProject = [
    {
      title: 'Google Maps',
      description:
        'Filtering directions using Google Maps API with REACT, WEBPACK, NODEJS, and SASS. Localized in english and german. Clickable custom markers with filtered data.',
      github: 'https://github.com/RaquelWriter/React-googlemap-api',
    },
    {
      title: 'Tintarantin',
      description:
        'Award winning app in C# for kids to learn to play piano. A website with real shopping cart for subscriptions, connected to the Paypal API. Blog with articles, and comments of the users.',
      website: 'https://tintarantin.com/',
    },
    {
      title: 'Shopping Cart',
      description:
        'Javascript demostration of a shopping cart. Filter data by coupon codes with limited time, selection of number and type of products and finish with the final price (VAT and discounts).',
      github: 'https://github.com/RaquelWriter/Javascript-Shopping-Cart',
    },
    {
      title: 'Hotel',
      description: 'Website connected to the reservations API. Information about the hotel, different articles, sliders and images database, forum with the comments of users about the hotel.',
      website: 'https://www.balcondecordoba.com/',
    },
    {
      title: 'Neya Sound',
      description:
        'Website for a YouTube musician. Include the youtube videos embedded and a subscription feature. MySQL, and WordPress with different plugins for better performance and SEO.',
      website: 'https://www.neyasound.com/',
    },
    {
      title: 'React Demo',
      description:
        'Demo of some features of React: React-Router, Hooks, Api fetching, States... Some of the apps are: COUNTDOWN from a choosen date from a calendar, POSTICKS: create and delete notes, CALCULATOR with advanced math functions, ROCK, PAPER, SCISSORS games, etc.',
      github: 'https://github.com/RaquelWriter/react-demo',
    },
  ];
  const getLinks = () => {
    if (textProject[index].hasOwnProperty('github')) {
      return (
        <>
          <a className="socialIcon" href={textProject[index].github} target="_blank" rel="noopener noreferrer">
            <BsGithub fontSize='2rem' />{' '}
          </a>
        </>
      );
    } else if (textProject[index].hasOwnProperty('website')) {
      return (
        <>
          <a className="socialIcon" href={textProject[index].website} target="_blank" rel="noopener noreferrer">
            <BsLink45Deg fontSize='2rem' />{' '}
          </a>
        </>
      );
    }
  };
  return (
    <>
      <div className='container'>
        <div className='wrapper-projects'>
          <div className='projects-description'>
            <h3>FINISHED PROJECTS</h3>
            <h2>{textProject[index].title}</h2>
            <p>{textProject[index].description}</p>
            <p>{getLinks()}</p>
          </div>

          {/* BOOTSTRAP CAROUSEL */}

          <Carousel
            className='carousel'
            activeIndex={index}
            onSelect={handleSelect}
          >
            <Carousel.Item>
              <img
                className='d-block w-100'
                src={GoogleMaps}
                alt='First slide'
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className='d-block w-100'
                src={Tintarantin}
                alt='Second slide'
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className='d-block w-100'
                src={ShoppingCart}
                alt='Third slide'
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className='d-block w-100'
                src={BalconDeCordoba}
                alt='Fourth slide'
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className='d-block w-100'
                src={NeyaSound}
                alt='Fifth slide'
              />
            </Carousel.Item>
          </Carousel>
          {/* ENDS BOOTSTRAP CAROUSEL */}
        </div>
      </div>
    </>
  );
}
export default Projects;
